html, body, #app, .my-container, div[data-overlay-container="true"] {
  // height: 100dvh;
  width: 100%;
  margin: 0;
}

@media only screen and (min-width: 500px) {
  // .my-container > div > div {
  //   justify-content: flex-end!important;
  // }
}

.white-bg .my-container {
  background-color: white;
}

.dark .my-container {
  background-color: black;
}

.my-container {
  padding-top: 30px;
  position: relative;
  min-height: 100dvh;
}

body {
  font-family: Roboto;
}

.reply-to-text {
  color: #065fd4;
  font-weight: 500;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

input {
  outline: none!important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.bg {
  width: 100%;
  // height: 100dvh;
  height: 100%;
  background-size: 300% 300%;
  background-image: linear-gradient(-45deg, rgba(255, 0, 0, 0.2) 0%, rgba(255, 0, 0, 0.2) 25%, rgba(255, 0, 0, 0.6) 51%, #ff357f 100%);
  -webkit-animation: AnimateBG 20s ease infinite;
          animation: AnimateBG 20s ease infinite;
}

@-webkit-keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.dark {
  color: #e3e3e3;
}

.white-bg {
  color: #29333d;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}