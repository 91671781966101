.list {
  list-style: none;
  padding-bottom: 32px;
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  gap: 26px;
  margin: 32px 20px 0;
}

.list-item {
  padding: 32px 20px;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
}

pre {
  font-family: Roboto !important;
  text-wrap: wrap;
  margin: 0;
  color: #29333d;
}

.dark pre {
  color: #d1d1d1;
}

.input-container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 12px;
  padding: 0 32px;
  align-items: center;
}

.input {
  height: 32px;
  font-size: 16px;
  font-family: Roboto;
  padding: 4px 10px;
  border: 1px solid;
  outline: none;
}

.button {
  svg {
    transition: margin .2s;
    margin-left: 0;
    margin-right: 3px;
  }
}

.button:hover {
  svg {
    margin-right: 0;
    margin-left: 3px;
  }
}

// .button {
//   background-color: #a2d2ff;
//   padding: 4px 10px;
//   border: 1px solid;
//   font-size: 16px;
// }

.author-name {
  color: black;
  font-weight: 500;
  display: block;
  text-decoration: none;
}

.thumbs {
  display: flex;
  gap: 12px;
  margin-top: 18px;

  div {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  a {
    color: inherit;
  }
}

.img {
  border-radius: 100%;
}

.channel-data {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 22px;

  small {
    color: #29333d;
  }
}

.total {
  margin-top: 32px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  b {
    font-size: 18px;
  }

  small {
    font-size: initial;
    font-weight: 300;
  }
}

.cursor-pointer-child {
  cursor: pointer;

  * {
    cursor: pointer !important;
  }
}

.width-500 {
  width: 500px !important;
}

@media only screen and (max-width: 800px) {
  .width-500 {
    width: 100% !important;
  }
}

.sort-direction {
  height: 20px;
  width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

// .sort-direction-container {
//   border-top-left-radius: 8px;
//   border-bottom-left-radius: 8px;
//   background-color: #F4F4F5;
//   display: flex;
//   align-items: center;
//   height: 48px;
// }

.sort-direction div {
  height: 4px;
  background-color: #494949;
  border-radius: 3px;
  transition: width .2s;
}

.sort-direction {
  div:nth-child(1) {
    width: 100%;
  }

  div:nth-child(2) {
    width: 60%;
  }

  div:nth-child(3) {
    width: 30%;
  }
}

.sort-direction.asc {
  div:nth-child(3) {
    width: 100%;
  }

  div:nth-child(2) {
    width: 60%;
  }

  div:nth-child(1) {
    width: 30%;
  }
}

.total-with-arrow {
  display: flex;
  gap: 12px;
  align-items: center;

  svg {
    fill: #494949;
    cursor: pointer;
  }
}

@media only screen and (max-width: 800px) {
  .get-commponents-input {
    width: 100% !important;

    div[data-slot="main-wrapper"],
    div[data-slot="input-wrapper"] {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 532px) {
  .input-container {
    flex-direction: column;
  }

  .extension span {
    display: inline !important;
  }

  .extension {
    display: none !important;
  }
}

.search-and-sort {
  display: flex;
}

.max-550 {
  max-width: 550px;
}

@media only screen and (max-width: 927px) {
  .total {
    gap: 18px;
    flex-direction: column;
  }

  .search-and-sort {
    width: 100%;
    justify-content: space-between;
  }

  .extension span {
    display: none;
  }

  .extension {
    padding: 0 !important;
    width: 55px !important;
    min-width: 55px !important;
  }
}

@media only screen and (max-width: 700px) {
  .warning span {
    font-size: 26px;
  }

  .warning {
    padding: 40px !important;
  }
}

@media only screen and (max-width: 590px) {
  .search-and-sort {
    gap: 18px;
    flex-direction: column;
    align-items: end;
  }

  .search-and-sort>div>button {
    margin-right: 0 !important;
  }
}

.button-search {
  margin-right: 20px;
}

@media only screen and (max-width: 390px) {
  .video-preview {
    width: 100%!important;
    margin: 0!important;
    padding: 0 20px;
  }
}

@media only screen and (max-width: 400px) {
  .my-pagination {
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .warning span {
    font-size: 18px;
  }

  .warning svg {
    font-size: 50px;
  }

  .warning {
    padding: 20px !important;
  }
}

.progress-bar-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  span {
    font-size: 24px;
    font-weight: 300;
  }

  width: 100%;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.extension {
  // font-family: 'Raleway';
  // color: white;
  // font-weight: 200;
  // font-size: 18px;
  // display: flex;
  // align-items: center;
  // position: absolute;
  // bottom: 29px;
  // text-decoration: underline;
  // gap: 12px;
  // right: 36px;

  img {
    height: 25px;
  }
}

.video-preview {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 20px;
  font-family: Roboto;
  // font-family: Arial, sans-serif;
  width: 320px;
  transition: .2s color;

  a:hover {
    color: black;
  }
}

.dark .video-preview a:hover {
  color: #b3b3b3;
}

.thumbnail-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.thumbnail {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.duration {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 2px 4px;
  font-size: 12px;
  border-radius: 3px;
}

.video-details {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
}

.channel-avatar {
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  // margin-right: 10px;
}

.video-info {
  // flex: 1;
  max-width: 260px;
  width: calc(100% - 50px);
}

.video-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 4px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dark .video-title {
  color: #e3e3e3;
}

.dark .video-meta {
  color: #8f8f8f;
}

.video-meta {
  font-size: 14px;
  color: #606060;
}

.more-options {
  font-size: 20px;
  cursor: pointer;
  padding-left: 10px;
}

.close-icon {
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 16px;
  font-weight: bold;
  transition: box-shadow .3s, transform .3s;
  display: flex;
  box-shadow: 0 4px 6px #0003;
  position: absolute;
  top: 8px;
  right: 8px;
}

.dark .close-icon {
  background-color: black;
}

.close-icon:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
  /* Stronger shadow on hover */
  transform: scale(1.1);
  /* Slight grow on hover */
}

.close-icon:active {
  transform: scale(0.9);
  /* Shrink when clicked */
}