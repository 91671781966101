.desktop-open {
  display: inline-flex!important;
}

.mobile-open {
  display: none!important;
}

@media only screen and (max-width: 590px) {
  .desktop-open {
    display: none!important;
  }

  .mobile-open {
    display: inline-flex!important;
  }
}