.desktop-open {
  display: inline-flex !important;
}

.mobile-open {
  display: none !important;
}

@media only screen and (width <= 590px) {
  .desktop-open {
    display: none !important;
  }

  .mobile-open {
    display: inline-flex !important;
  }
}

.list {
  flex-direction: column;
  gap: 26px;
  margin: 32px 20px 0;
  padding-bottom: 32px;
  font-family: Roboto;
  list-style: none;
  display: flex;
}

.list-item {
  padding: 32px 20px;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
}

pre {
  text-wrap: wrap;
  color: #29333d;
  margin: 0;
  font-family: Roboto !important;
}

.dark pre {
  color: #d1d1d1;
}

.input-container {
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 0 32px;
  display: flex;
}

.input {
  border: 1px solid;
  outline: none;
  height: 32px;
  padding: 4px 10px;
  font-family: Roboto;
  font-size: 16px;
}

.button svg {
  margin-left: 0;
  margin-right: 3px;
  transition: margin .2s;
}

.button:hover svg {
  margin-left: 3px;
  margin-right: 0;
}

.author-name {
  color: #000;
  font-weight: 500;
  text-decoration: none;
  display: block;
}

.thumbs {
  gap: 12px;
  margin-top: 18px;
  display: flex;
}

.thumbs div {
  align-items: center;
  gap: 6px;
  display: flex;
}

.thumbs a {
  color: inherit;
}

.img {
  border-radius: 100%;
}

.channel-data {
  align-items: center;
  gap: 12px;
  margin-bottom: 22px;
  display: flex;
}

.channel-data small {
  color: #29333d;
}

.total {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 32px;
  padding: 0 20px;
  display: flex;
}

.total b {
  font-size: 18px;
}

.total small {
  font-size: initial;
  font-weight: 300;
}

.cursor-pointer-child {
  cursor: pointer;
}

.cursor-pointer-child * {
  cursor: pointer !important;
}

.width-500 {
  width: 500px !important;
}

@media only screen and (width <= 800px) {
  .width-500 {
    width: 100% !important;
  }
}

.sort-direction {
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  width: 20px;
  height: 20px;
  display: flex;
}

.sort-direction div {
  background-color: #494949;
  border-radius: 3px;
  height: 4px;
  transition: width .2s;
}

.sort-direction div:first-child {
  width: 100%;
}

.sort-direction div:nth-child(2) {
  width: 60%;
}

.sort-direction div:nth-child(3) {
  width: 30%;
}

.sort-direction.asc div:nth-child(3) {
  width: 100%;
}

.sort-direction.asc div:nth-child(2) {
  width: 60%;
}

.sort-direction.asc div:first-child {
  width: 30%;
}

.total-with-arrow {
  align-items: center;
  gap: 12px;
  display: flex;
}

.total-with-arrow svg {
  fill: #494949;
  cursor: pointer;
}

@media only screen and (width <= 800px) {
  .get-commponents-input, .get-commponents-input div[data-slot="main-wrapper"], .get-commponents-input div[data-slot="input-wrapper"] {
    width: 100% !important;
  }
}

@media only screen and (width <= 532px) {
  .input-container {
    flex-direction: column;
  }

  .extension span {
    display: inline !important;
  }

  .extension {
    display: none !important;
  }
}

.search-and-sort {
  display: flex;
}

.max-550 {
  max-width: 550px;
}

@media only screen and (width <= 927px) {
  .total {
    flex-direction: column;
    gap: 18px;
  }

  .search-and-sort {
    justify-content: space-between;
    width: 100%;
  }

  .extension span {
    display: none;
  }

  .extension {
    width: 55px !important;
    min-width: 55px !important;
    padding: 0 !important;
  }
}

@media only screen and (width <= 700px) {
  .warning span {
    font-size: 26px;
  }

  .warning {
    padding: 40px !important;
  }
}

@media only screen and (width <= 590px) {
  .search-and-sort {
    flex-direction: column;
    align-items: end;
    gap: 18px;
  }

  .search-and-sort > div > button {
    margin-right: 0 !important;
  }
}

.button-search {
  margin-right: 20px;
}

@media only screen and (width <= 390px) {
  .video-preview {
    padding: 0 20px;
    width: 100% !important;
    margin: 0 !important;
  }
}

@media only screen and (width <= 400px) {
  .my-pagination {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .warning span {
    font-size: 18px;
  }

  .warning svg {
    font-size: 50px;
  }

  .warning {
    padding: 20px !important;
  }
}

.progress-bar-container {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 20px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.progress-bar-container span {
  font-size: 24px;
  font-weight: 300;
}

.extension img {
  height: 25px;
}

.video-preview {
  flex-direction: column;
  width: 320px;
  margin: 0 auto 20px;
  font-family: Roboto;
  transition: color .2s;
  display: flex;
}

.video-preview a:hover {
  color: #000;
}

.dark .video-preview a:hover {
  color: #b3b3b3;
}

.thumbnail-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.thumbnail {
  object-fit: cover;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  position: absolute;
}

.duration {
  color: #fff;
  background-color: #000000bf;
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 12px;
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.video-details {
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  display: flex;
}

.channel-avatar {
  border-radius: 50%;
  min-width: 40px;
  height: 40px;
}

.video-info {
  width: calc(100% - 50px);
  max-width: 260px;
}

.video-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.2;
  display: block;
  overflow: hidden;
}

.dark .video-title {
  color: #e3e3e3;
}

.dark .video-meta {
  color: #8f8f8f;
}

.video-meta {
  color: #606060;
  font-size: 14px;
}

.more-options {
  cursor: pointer;
  padding-left: 10px;
  font-size: 20px;
}

.close-icon {
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 16px;
  font-weight: bold;
  transition: box-shadow .3s, transform .3s;
  display: flex;
  position: absolute;
  top: 8px;
  right: 8px;
  box-shadow: 0 4px 6px #0003;
}

.dark .close-icon {
  background-color: #000;
}

.close-icon:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px #0000004d;
}

.close-icon:active {
  transform: scale(.9);
}

html, body, #app, .my-container, div[data-overlay-container="true"] {
  width: 100%;
  margin: 0;
}

.white-bg .my-container {
  background-color: #fff;
}

.dark .my-container {
  background-color: #000;
}

.my-container {
  min-height: 100dvh;
  padding-top: 30px;
  position: relative;
}

body {
  font-family: Roboto;
}

.reply-to-text {
  color: #065fd4;
  cursor: pointer;
  font-weight: 500;
}

* {
  box-sizing: border-box;
}

input {
  outline: none !important;
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out;
}

input:-webkit-autofill:hover {
  transition: background-color 5000s ease-in-out;
}

input:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out;
}

input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out;
}

.bg {
  background-image: linear-gradient(-45deg, #f003 0% 25%, #f009 51%, #ff357f 100%);
  background-size: 300% 300%;
  width: 100%;
  height: 100%;
  animation: 20s infinite AnimateBG;
}

@keyframes AnimateBG {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.dark {
  color: #e3e3e3;
}

.white-bg {
  color: #29333d;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-results-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
/*# sourceMappingURL=index.4ccc8ab5.css.map */
